import React, { Component } from 'react'
import { connect } from "react-redux"
import { nextStep , previousStep , onValueChange , setError } from "../../../actions/index"
import "./Racking.scss"
import "./Roof.scss"
import SelectSearch from 'react-select-search';
import { isEmpty } from '../../../utils/validators';

class Racking extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleBack = () => {
    const { onValueChange , previousStep } = this.props;

    onValueChange({fieldName:"errorText",value:false})
    previousStep();
  }

  handleClick = (e) =>{

    const { strConsider , rackingDetails, inverterEase , localPresence , nextStep , setError , onValueChange } = this.props;

    if( isEmpty({ strConsider , inverterEase , localPresence , rackingDetails })){
      setError("Please Fill All The Fields");
    }
    else{
      onValueChange({fieldName:"errorText",value:false})
      nextStep();
    }

  }

  handleSearch = async (query) => {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/racking/${query}`,{
      method:"GET"
    })

    const resData = await res.json()

    if(res.status===202){
      const rackingSearch = resData.map(item=>{
        return{
          ...item,
          key:item.id,
          value:item.company_name,
          fieldName:"rackingDetails",
          name:item.company_name
        }
      })

      return rackingSearch;
    }

  }

  render() {

    const { vendorName,strConsider , rackingDetails , errorText , onValueChange , selectedRacker , inverterEase , localPresence } = this.props;

    return (

      <div>
      <div className="categories">
      <div className="racking">
        <h2 className="racking__heading" >RACKING - {vendorName}</h2>
        <div className="racking__form">
          <div className={ errorText && rackingDetails === "" ? "racking__form--manufacturer textfield error-border" :"racking__form--manufacturer textfield"}>
            <label htmlFor="rackingDetails">Racking Manufacturer</label>
            <SelectSearch
              onChange={(option,optionObj)=>{
                onValueChange({ fieldName:"selectedRacker" , value:optionObj })
                onValueChange({ fieldName:"rackingDetails" , value:optionObj.id })
              }}
              options = { selectedRacker ? [selectedRacker] : []}
              value = { selectedRacker ? selectedRacker.name : null}
              getOptions={ this.handleSearch }  search
              placeholder="Search Racking Manufacturer"
            />
          </div>
          <div className={ errorText && localPresence === "" ? "iconbutton  error-border" :"iconbutton"}>
            <label htmlFor="localPresence">Manufacturer Local Presence</label>
            <input className="iconbutton--yes" type="radio" name="localPresence" value="yes" checked={localPresence==="yes"}
            onChange={(e) => {
              onValueChange(e);
              onValueChange({fieldName:"inverterEase" , value:"yes"});
            }}/> Yes
            <input className="iconbutton--no" type="radio" name="localPresence" value="no" checked={localPresence==="no"}
            onChange={(e) => {
             onValueChange(e);
             onValueChange({fieldName:"inverterEase" , value:"no"});
            }}/> No
          </div>
          <div className={ errorText && inverterEase === "" ? "iconbutton error-border" :"iconbutton"}>
            <label htmlFor="inverterEase">Ease Of Installation</label>
            <input className="iconbutton--yes" type="radio" name="inverterEase" value="yes" checked={inverterEase==="yes"} onChange={this.handleChange}/> Yes
            <input className="iconbutton--no" type="radio" name="inverterEase" value="no" checked={inverterEase==="no"} onChange={this.handleChange}/> No
          </div>
          <div className={ errorText && strConsider === "" ? "iconbutton error-border" :"iconbutton" }>
            <label htmlFor="strConsider">Structural Considerations</label>
            <input className="iconbutton--yes" type="radio" name="strConsider" value="yes" checked={strConsider==="yes"} onChange={this.handleChange}/> Yes
            <input className="iconbutton--no" type="radio" name="strConsider" value="no" checked={strConsider==="no"} onChange={this.handleChange}/> No
          </div>
        </div>
      </div>
        </div>
         <div className="button__form">
           <div>
                <button onClick={this.handleBack} className="roof__back">
                <div className="backbutton__form">
                  <div className="back-button-icon"><i className='fas fa-long-arrow-alt-left'></i></div>
                  <div className="back-button-text">Back</div>
                </div>
                </button>
            </div>
            <div>
          <button onClick={this.handleClick} className="roof__next ">
            <div className="nextbutton__form">
            <div className="next-button-text">Next</div>
            <div className="next-button-icon"><i className='fas fa-long-arrow-alt-right'></i></div>
            </div>
          </button>
             </div>
      </div>
  </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    strConsider : state.addComponent.strConsider,
    inverterEase : state.addComponent.inverterEase,
    localPresence : state.addComponent.localPresence,
    rackingDetails : state.addComponent.rackingDetails,
    selectedRacker : state.addComponent.selectedRacker,
    errorText : state.addComponent.errorText,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nextStep : () => dispatch(nextStep()),
    previousStep : () => dispatch(previousStep()),
    onValueChange : (e) => dispatch(onValueChange(e)),
    setError : (text) =>dispatch(setError(text))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(Racking);
