import React, { Component } from 'react';
import { Route ,Switch, Redirect } from 'react-router-dom';
import Login from '../../Pages/Login';
import Home from '../../Pages/Home';
import HomeScreen from '../../Pages/HomeScreen'
// import ThankYou from '../Packages/ThankYou.js'
import { connect }  from 'react-redux';

class Routes extends Component {


  render() {
    const { isAuth } = this.props;

    let validRoutes =[ <Route key="0" exact path="/" component={HomeScreen} ></Route>,
  <Route key="1" exact path="/login" component={Login} ></Route>
]
    if(isAuth){
      validRoutes = <Route path="/home" component={Home} ></Route>
    }

    return (
      <Switch>
        {validRoutes}
        <Redirect to={isAuth?"/home":"/login"} />
      </Switch>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    isAuth:state.auth.isAuth
 }
}

export default connect( mapStatesToProps )(Routes);
