import React from "react";
import Modal from "@material-ui/core/Modal";
import "./PackageForm.scss";
import { Link } from "react-router-dom";


export default function SimpleModal(props) {


  // getModalStyle is not a pure function, we roll the style only on the first render
  const initialFormData = Object.freeze({
  name: "",
  email:"",
  phone_no:"",
  company: "",
  package:props.package
});
const [formData, updateFormData] = React.useState(initialFormData);

const handleChange = (e) => {
  updateFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const handleSubmit = (e) => {
  // ... submit to API

  fetch('https://eqgauge.com/api/query',
  {
    method: 'POST',
    headers:{
      "Content-Type":"application/json"
    },
    body: JSON.stringify(formData),
  });

};
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (

    <div  className="packageform">
  <h1 className="packageform__heading">Please provide details below to confirm service acceptance.</h1>
  <form>
          <input className="packageinput__fieldOne" onChange={handleChange} id="name" name="name" type="text" placeholder="Name"/>
          <input className="packageinput__fieldTwo" onChange={handleChange} id="company" name="company" type="text"  placeholder="Company" /><br></br>
          <input className="packageinput__fieldOne" onChange={handleChange} id="email" name="email" placeholder="E-mail"/>
          <input className="packageinput__fieldTwo" onChange={handleChange} id="phone_no" name="phone_no" type="tel"  placeholder="Phone"/><br></br>
          <div style={{width:"100%",textAlign:"right",marginRight:"20px"}}>
          <Link onClick={handleSubmit} className="packageinput__button" to="/home/thankyou"> Submit </Link>
          </div>
        </form>
    </div>
  );

  return (
    <div>
      <button className="buy__button" type="button" onClick={handleOpen}>
        Buy
      </button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
