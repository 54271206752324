import React , { Component } from 'react';
import { connect } from "react-redux"
import { getGraphs , onValueChange } from "../../../actions/index"
import { graphLineColors } from  "../../../utils/utilityFunctions"
import { Line } from "react-chartjs-2"
import { defaults } from 'react-chartjs-2'
import "./Graph.scss"
import "../AddCompany/Roof.scss";
import "../Compare/Compare.scss";


defaults.global.defaultFontColor = 'black';
defaults.global.defaultFontFamily = 'Manrope';


class Graph extends Component {

  componentDidMount(){
    const { allCompanies , getGraphs } = this.props;
    getGraphs(allCompanies);
  }
  handleClick = () => {
    this.props.onValueChange({ fieldName:"searching" , value:false })
    this.props.history.push("/home/packages")
  }


  graphContent = () => {

    const { graphScores , graphYears , graphSelection , allCompanies } = this.props

    if( graphScores === null )
      return null;
    else {

      const savingsData = { ...graphYears["Vendors"] , eqgauage : graphYears["eqguage"] }
      const graphSelected = graphSelection ? graphScores : savingsData;
      const labelScores = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ");
      const labelSavings = ["Year 1" , "Year 5" , "Year 10"]

      const chartData = {
        labels: graphSelection ? labelScores : labelSavings,
        datasets : Object.keys(graphSelected).map((key , index) => {
          const newColor = graphLineColors(index);
          console.log(newColor);
          return {
            label:key==="eqgauage" ? "Optimised solution" : allCompanies[index].vendorName  ,
            data : Object.keys(graphSelected[key]).map((key2) => {
              return graphSelected[key][key2]
            }),
            backgroundColor: "#EAF2F0",
            borderColor:key==="eqgauage" ?"rgba(30, 149, 124" + ",0.5)":newColor + ",0.5)",
            lineTension:"0"
          }
        })
      }
      return chartData;
    }
  }

  handleTabs = (e) => {
    const { onValueChange } = this.props;

    if(e.target.value==="Scores")
      onValueChange({fieldName:"graphSelection",value:true})
    else if(e.target.value==="Savings")
      onValueChange({fieldName:"graphSelection",value:false})
  }

  render(){
    const { searching , allCompanies , graphSelection  } = this.props;
    const chartData = this.graphContent();
    return (
      <div>
      <div className="add__container">
        <div  className="add__line"></div>
        <div className="add__circleOne"></div>
        <div className="add__circleTwo"></div>
      </div>
      <div className="graph">
        <h1 className="graph__heading">Graphical Comparison</h1>
        <div className="graph__tabs">
          <button className={graphSelection ? "graph__tabs__btn active" : "graph__tabs__btn"} onClick={this.handleTabs} value="Scores" >Generation (Kwh)</button>
          <button className={graphSelection ? "graph__tabs__btn" : "graph__tabs__btn active"} onClick={this.handleTabs} value="Savings">Savings Graph</button>
        </div>
        {
          !searching && allCompanies.length !== 0 ?
          <h2 className="graph__heading">Loading...</h2> :
          ( chartData ?
          <div className="outer__container">
          <div>        { graphSelection ?
                    <ul className="list">
                    <li>Safety Considerations not Optimal</li>
                    <li>Product Selection to be reviewed</li>
                    <li>Review Orientation</li>
                    <li>Review Tilt/Spacing</li>
                    <li>Run Thermal Mapping</li>
                    <li>Run Shading Analysis</li>
                    </ul>
                     :
                     <ul className="list">
                     <li>Review Bill of Material for selection of isolators, choice of combiner box</li>
                     <li>Review Financing Options</li>
                     <li>Review Market rebates</li>
                     </ul>
                   }</div>
          <div className="graph__container" >
            <Line
              className="graph__main"
              data={chartData}
              options={{
                maintainAspectRatio : false,
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: graphSelection?"Generation (Kwh)":"Savings ($)",
                      fontSize:"18"
                    },
                    gridLines: {
                       display:false
                    },
                    ticks: {
                      beginAtZero: true,
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: graphSelection?"Months":"Years",
                      fontSize:"18"
                    },
                    gridLines: {
                       display:false
                    }
                  }]
                },
                layout:{
                  padding:{
                    left : "50",
                    right : "50",
                    top : "50",
                    bottom : "50"
                  }
                },
                legend:{
                  display:true,
                  position:"bottom"
                },
              }}
            />
          </div>

          </div>:
          <h4 className="graph__heading" >No Vendors To Compare</h4>
          )
        }
      </div>
      <div className="button__form">
      <div>
      </div>
        <div>
        <button value="done" onClick={this.handleClick} className="roof__next">
            Finish
          </button>
         </div>
       </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    allCompanies:state.addComponent.allCompanies,
    searching:state.addComponent.searching,
    graphScores:state.addComponent.graphScores,
    graphYears:state.addComponent.graphYears,
    graphSelection:state.addComponent.graphSelection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGraphs : (allVendors) => dispatch(getGraphs(allVendors)),
    onValueChange : (event) => dispatch(onValueChange(event))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(Graph);
