import React , { Component } from 'react';
import "./Packages.scss";


class ThankYou extends Component {

  render(){

    return (
      <div style={{width:"80%",position:"absolute",textAlign:"center",top:"50%",left:"10%"}}>
      <h1>Thankyou for showing interest we will get back to you.</h1>
      </div>

    )
  }
}

export default (ThankYou);
