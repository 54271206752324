import { MAIN_ERROR , CLEAR_ERROR } from "../actions/actionTypes"

const intialState = {
  error:false,
  errorMsg:null
}

export default (state = intialState , action ) => { 
  switch(action.type){
    case MAIN_ERROR :
      return {
        ...state,
        error : true,
        errorMsg : action.errorMsg
      }
    case CLEAR_ERROR :
      return {
        ...state,
        error : false
      }
    default :
      return state;    
  }
}