import React, { Component } from 'react'
import { connect } from "react-redux"
import { nextStep  , onValueChange , setError , panelDetails , previousStep }from "../../../actions/index"
import { isEmpty } from "../../../utils/validators"
import "./PanelDynamics.scss"
import "./Roof.scss"
import "./DesignParameters.scss"
import SelectSearch from 'react-select-search';

class PanelDynamics extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleClick = () => {

    const { panelManufacturer , panelCells , panelWatt , panelEfficiency ,panelWarranty , nextStep , setError , onValueChange } = this.props;

    if(isEmpty({ panelManufacturer , panelCells , panelEfficiency , panelWatt , panelWarranty })){
      setError("Please Fill All The Fields");
    }
    else{
      onValueChange({fieldName:"errorText",value:false})
      nextStep();
    }

  }

  handleBack = () => {
    const { onValueChange , previousStep } = this.props;

    onValueChange({fieldName:"errorText",value:false})
    previousStep();
  }

  handleSearch = async (query) => {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/panels/${query}`,{
      method:"GET"
    })
    const resData = await res.json()

    if(res.status===202){
      const panelSearch = resData.map(item=>{
        return{
          ...item,
          key:item.manufacturer,
          value:item.manufacturer,
          fieldName:"panelManufacturer",
          name:item.manufacturer
        }
      })
      return panelSearch;
    }
  }


  manufacturerChange = (option,optionObj) => {
    const { onValueChange , panelDetails } = this.props;

    onValueChange({ fieldName:"selectedPanel" , value:optionObj })
    onValueChange({ fieldName:"panelManufacturer" , value:option })
    onValueChange({ fieldName:"panelWatt" , value:"" })
    onValueChange({ fieldName:"panelEfficiency" , value:"" })
    onValueChange({ fieldName:"searching" , value:true })
    panelDetails(option);
  }

  wattChange = (e) => {
    const { onValueChange , manfacturerDetails } = this.props;

    this.handleChange(e)
    const wattDetails = manfacturerDetails.find(x=>{
      return (Number(x.wattage) === Number(e.target.value));
    })
    onValueChange({ fieldName:"panelEfficiency" , value:wattDetails.efficiency })
  }


  render() {
    const { vendorName,panelManufacturer , panelCells , panelWatt , panelEfficiency ,panelWarranty , errorText , selectedPanel , manfacturerDetails  , searching } = this.props;

    return (
      <div>
      <div className="categories">
      <div className="panel">
        <h2 className="panel__heading">PANEL DYNAMICS - {vendorName}</h2>
        <div className="panel__form">
          <div className={ errorText && panelManufacturer === "" ? "panel__form--manufacturer textfield error-border" :"panel__form--manufacturer textfield"}>
            <label htmlFor="panelManufacturer">Solar Panel Manufacturer</label>
            <SelectSearch
              onChange = { this.manufacturerChange }
              options = { selectedPanel ? [selectedPanel] : []}
              value = { selectedPanel && selectedPanel.name }
              getOptions={ this.handleSearch }
              search
              placeholder="Search Panel Manufacturers"
            />
          </div>
          <div className={ errorText && panelCells === "" ? "radiobutton error-border":"radiobutton"}>
            <label htmlFor="panelCells">Cell Type</label>
            <span className="textsize">
              <input type="radio" name="panelCells" value="60" checked={panelCells === "60"} onChange={this.handleChange} /> 60
            </span>
            <span className="textsize">
              <input type="radio" name="panelCells" value="72" checked={panelCells === "72"} onChange={this.handleChange} /> 72
            </span>
          </div>
          <div className={ errorText && panelWatt === "" ? "textfield error-border" : "textfield"}>
            <label htmlFor="panelWatt">Panel Wattage</label>
            <select
              name="panelWatt"
              disabled={ searching || manfacturerDetails === null }
              id="panelWatt"
              value={ panelWatt === "" ? "default" : panelWatt }
              onChange = {this.wattChange}
            >
              <option value="default" hidden disabled > { manfacturerDetails ? "Select Option" : "Select Option"} </option>
              {manfacturerDetails &&
              manfacturerDetails.map((item,index)=>{
                return <option key={index} id={item.efficiency} value={item.wattage}>{item.wattage}</option>
              })}
            </select>
          </div>
          <div className={ errorText && panelEfficiency === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="panelEfficiency">Panel Efficiency</label>
            <input type="text" placeholder="Select Watt Details" name="panelEfficiency" id="panelEfficiency" disabled value={ panelEfficiency.toString().substring(0,5) }/>
          </div>
          <div className={ errorText && panelWarranty === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="panelWarranty">Panel Warranty</label>
            <select name="panelWarranty" onChange={this.handleChange} id="panelWarranty" defaultValue={ panelWarranty !=="" ? panelWarranty: "default"}>
              <option value="default" hidden disabled > Select Option </option>
              <option value={0} >Less than 5 years</option>
              <option value={0.08} >Between 5-10 years</option>
              <option value={0.1} >Between 10-12 years</option>
              <option value={0.25} >More than 12 years</option>
            </select>
          </div>
        </div>
      </div>
        </div>
        <div className="button__form">
        <div>
        <button onClick={this.handleBack} className="roof__back">
          <div className="backbutton__form">
            <div className="back-button-icon"><i className='fas fa-long-arrow-alt-left'></i></div>
            <div className="back-button-text">Back</div>
          </div>
        </button>
        </div>
        <div>
      <button onClick={this.handleClick} className="roof__next ">
        <div className="nextbutton__form">
        <div className="next-button-text">Next</div>
        <div className="next-button-icon"><i className='fas fa-long-arrow-alt-right'></i></div>
        </div>
      </button>
         </div>
    </div>
        </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    panelManufacturer : state.addComponent.panelManufacturer,
    panelCells : state.addComponent.panelCells,
    panelWatt : state.addComponent.panelWatt,
    panelEfficiency : state.addComponent.panelEfficiency,
    panelWarranty : state.addComponent.panelWarranty,
    selectedPanel : state.addComponent.selectedPanel,
    manfacturerDetails : state.addComponent.manfacturerDetails,
    searching : state.addComponent.searching,
    errorText : state.addComponent.errorText,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nextStep : () => dispatch(nextStep()),
    previousStep : () => dispatch(previousStep()),
    onValueChange : (e) => dispatch(onValueChange(e)),
    setError : (text) => dispatch(setError(text)),
    panelDetails : (panelManufacturer) => dispatch(panelDetails(panelManufacturer))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(PanelDynamics);
