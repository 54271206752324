import React from 'react';
import { makeStyles , withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 38,
    "@media(max-width:500px)":{
      top: 10,
      left:-20
    }
  },
  active: {
    '& $line': {
      background: '#000',
    },
  },
  completed: {
    '& $line': {
      background: '#000',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#000',
    borderRadius: 1,
    "@media(max-width:500px)":{
      width : 50
    }
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#F8F8F8',
    zIndex: 1,
    border:"2px solid #A0A0A0",
    fontSize:"0.65rem",
    color: '#000',
    width: 80,
    textAlign: "center",
    height: 80,
    padding:"5px",
    display: 'flex',
    borderRadius: '50%',
    fontWeight:"600",
    justifyContent: 'center',
    alignItems: 'center',
    "@media(max-width:500px)":{
      width: 25,
      height: 25,
      fontSize: "0.5rem",
      border:"2px solid #000"
    }
  },
  active: {
    background:"#89c9bb",
      border:"3px solid #1E957C",
    color:"#1E957C",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    fontWeight:"600",
  },
  completed: {
    border:"2px solid #A0A0A0",
    background:'#F8F8F8',
    fontWeight:"600",
  },
});

export  function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <p>ROOF DETAILS</p>,
    2: <p>PANEL DYNAMICS</p>,
    3: <p>INVERTER DYNAMICS</p>,
    4: <p>RACKING</p>,
    5: <p>DESIGN PARAMETERS</p>,
    6: <p>VALUE ADDITIONS</p>
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
