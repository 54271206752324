import React, { Component } from 'react'
import { connect } from "react-redux"
import { nextStep , previousStep , onValueChange , setError } from "../../../actions/index"
import "./InverterDynamics.scss"
import "./Roof.scss"
import "./DesignParameters.scss"
import SelectSearch from 'react-select-search';
import { isEmpty , isPositive } from "../../../utils/validators"

class InverterDynamics extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleBack = () => {
    const { onValueChange , previousStep } = this.props;

    onValueChange({fieldName:"errorText",value:false})
    previousStep();
  }


  handleClick = () =>{

    const { nextStep , inverterManufacturer , inverterWatt , setError , mppt ,optimiser , inverterLocation, onValueChange } = this.props;

    if( isEmpty({ inverterManufacturer , inverterWatt , mppt ,optimiser , inverterLocation}) )
      setError("Please Fill All The Fields");
    else if( isPositive({inverterWatt}))
      setError("Invalid Inverter Wattage");
    else{
      onValueChange({fieldName:"errorText",value:false})
      nextStep();
    }

  }

  handleSearch = async (query) => {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/inverter/${query}`,{
      method:"GET"
    })

    const resData = await res.json()

    if(res.status===202){
      const manufacturerSearch = resData.map(item=>{
        return{
          ...item,
          key:item.manufacturer,
          value:item.manufacturer,
          fieldName:"inverterManufacturer",
          name:item.manufacturer
        }
      })

      return manufacturerSearch;
    }

  }

  render() {
    const { vendorName,inverterManufacturer , inverterWatt ,errorText , onValueChange , optimiser , inverterLocation,selectedInverter , mppt } = this.props;

    return (
      <div>
      <div className="categories">
      <div className="inverter">
        <h2 className="inverter__heading">INVERTER DYNAMICS - {vendorName}</h2>
        <div className="inverter__form">
          <div className={ errorText && inverterManufacturer === "" ? "inverter__form--manufacturer textfield error-border" :"inverter__form--manufacturer textfield"}>
            <label htmlFor="inverterManufacturer">Inverter Manufacturer</label>
            <SelectSearch
              onChange={(option,optionObj)=>{
                onValueChange({ fieldName:"selectedInverter" , value:optionObj })
                onValueChange({ fieldName:"inverterManufacturer" , value:option })
              }}
              options = { selectedInverter ? [selectedInverter] : []}
              value = { selectedInverter ? selectedInverter.name : null}
              getOptions={ this.handleSearch }
              search
              placeholder="Search Inverter Manufacturer"
            />
          </div>
          <div className={ errorText && mppt === "" ? "iconbutton error-border" :"iconbutton"}>
            <label htmlFor="mppt">Multiple MPPT</label>
            <input className="iconbutton--yes" type="radio" name="mppt" value="yes" checked={mppt==="yes"} onChange={this.handleChange}/> Yes
            <input className="iconbutton--no" type="radio" name="mppt" value="no" checked={mppt==="no"} onChange={this.handleChange}/> No
          </div>
          <div className={ errorText && (inverterWatt === "" || Number(inverterWatt) <= 0) ? "textfield error-border" :"textfield"}>
            <label htmlFor="inverterWatt">Total Inverter Wattage (in KW)</label>
            <input type="number" placeholder="Greater Than 0" name="inverterWatt" id="inverterWatt" value={inverterWatt} onChange={this.handleChange}/>
          </div>
          <div className={ errorText && inverterLocation === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="inverterLocation">Inverter Location</label>
            <select name="inverterLocation" onChange={this.handleChange} id="inverterLocation" defaultValue={ inverterLocation !== "" ? inverterLocation : "default" } >
              <option value="default" hidden disabled> Select Option </option>
              <option value="Inside">Inside</option>
              <option value="Outside">Outside</option>
              <option value="Cage Included">Cage Included</option>
            </select>
          </div>
          <div className={ errorText && optimiser === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="optimiser">Optimiser Used</label>
            <select name="optimiser" onChange={this.handleChange} id="optimiser" defaultValue={ optimiser ? optimiser : "default" }>
              <option value="default" hidden disabled> Select Option </option>
              <option value={0.95}>Single Optis</option>
              <option value={0.75}>Dual Optis</option>
              <option value={0}>None</option>
            </select>
          </div>
        </div>
      </div>
          </div>
          <div className="button__form">
          <div>
          <button onClick={this.handleBack} className="roof__back">
          <div className="backbutton__form">
            <div className="back-button-icon"><i className='fas fa-long-arrow-alt-left'></i></div>
            <div className="back-button-text">Back</div>
          </div>
          </button>
      </div>
      <div>
    <button onClick={this.handleClick} className="roof__next ">
      <div className="nextbutton__form">
      <div className="next-button-text">Next</div>
      <div className="next-button-icon"><i className='fas fa-long-arrow-alt-right'></i></div>
      </div>
    </button>
       </div>
      </div>
        </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    inverterManufacturer : state.addComponent.inverterManufacturer,
    inverterWatt : state.addComponent.inverterWatt,
    selectedInverter : state.addComponent.selectedInverter,
    mppt : state.addComponent.mppt,
    errorText : state.addComponent.errorText,
    optimiser : state.addComponent.optimiser,
    inverterLocation : state.addComponent.inverterLocation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nextStep : () => dispatch(nextStep()),
    previousStep : () => dispatch(previousStep()),
    onValueChange : (e) => dispatch(onValueChange(e)),
    setError : (text) => dispatch(setError(text))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(InverterDynamics);
