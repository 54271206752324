import { LOGIN , LOGOUT , HANDLE_CREDENTIALS , MAIN_ERROR} from "./actionTypes"

export const handleCredentials = (event) => ({ type : HANDLE_CREDENTIALS , event : event})


export const loginHandler = (credentails) => async (dispatch) => {
    
  const res = await fetch(`${process.env.REACT_APP_API_URL}/login`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      username: credentails.email,
      password: credentails.password
    })
  })

  const resData = await res.json()

  if(res.status === 202){
    localStorage.setItem("userDetails",resData.name)
    dispatch({ type : LOGIN , userDetails : resData.name })
  }
  else if(res.status === 404)
    dispatch({ type : MAIN_ERROR , errorMsg : resData.status })
}


export const logoutHandler = () => {

  return (dispatch) => { 
    localStorage.removeItem("userDetails")
    dispatch({ type : LOGOUT })
  }
}


