export const isEmpty = (val) => {
  
  const isEmpty = Object.keys(val).every(key => val[key] !== "" )
  return !isEmpty;
}

export const isPositive = (val) => {
  
  const isPositive = Object.keys(val).every(key => val[key] > 0 )
  return !isPositive;
}