import React, { Component } from 'react'
import { connect }  from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { loginHandler , handleCredentials , setError } from "../../actions/index"
import logo from "../../images/logo.png"
import Error from "../Other/Utilities/Error"
import { isEmpty } from "../../utils/validators"
import "./Login.scss"

class Login extends Component {

  handleChange = (e) => {
    this.props.handleCredentials(e);
  }

  handleLogin = () =>{
    const { email , password , loginHandler , setError } = this.props;

    const inputs = { email , password }

    if(isEmpty(inputs))
      setError("Fill all details");
    else
      loginHandler(inputs)
  }

  render() {
    const { email, password } = this.props;
    return (
      <div className="login">
        <div className="login__logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="login__container">
          <h1 className="login__container_heading">Sign In</h1>
          <form className="login__container__form">
            <div className="login__container__form__group">
              <div className="login__container__form__group__icon">
                <FontAwesomeIcon icon="user"/>
              </div>
              <input type="email" name="email" placeholder="Email" value={ email } onChange={this.handleChange}/>
            </div>
            <div className="login__container__form__group">
              <div className="login__container__form__group__icon">
                <FontAwesomeIcon icon="lock"/>
              </div>
              <input type="password" name="password" placeholder="Password" value={ password } onChange={this.handleChange} />
            </div>
            <Link className="login__container__form__forget" to="#">Forgot Your Password? Click Here!</Link>
            <Link onClick={this.handleLogin} className="login__container__form__btn btn--primary" to="/home"> Login </Link>
          </form>
        </div>
        <Error/>
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    email:state.auth.email,
    password:state.auth.password
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginHandler : (credentials) => dispatch(loginHandler(credentials)),
    handleCredentials : (e) => dispatch(handleCredentials(e)),
    setError : (text) => dispatch(setError(text))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(Login);
