export const graphLineColors =(index) => {
  if(index===0)
    return 'rgba(231,76,60'  ;
  if(index===1)
    return 'rgba(40,116,166'  ;
  if(index===2)
    return 'rgba(113,125,126'  ;
  if(index===3)
    return 'rgba(99,57,116'  ;
  const o = Math.round;
  const r = Math.random;
  const s = 255;
  return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s)  ;
}