import { LOGOUT , LOGIN , HANDLE_CREDENTIALS } from "../actions/actionTypes"


const userData = localStorage.getItem("userDetails");

const intialState = {
  isAuth: userData ? true: false,
  userDetails: userData || null ,
  email:"",
  password:""
}

export default (state = intialState , action ) => {
  switch(action.type){
    case LOGIN :
      return {
        ...state,
        isAuth : true,
        userDetails : action.userDetails
      }
    case LOGOUT :
      return {
        ...state,
        isAuth : false ,
        userDetails : null
      }
    case HANDLE_CREDENTIALS :
      return {
        ...state,
        [action.event.target.name] : action.event.target.value
      }
    default :
      return state;
  }
}
