import React, { Component } from 'react';
import InverterDynamics from "./InverterDynamics"
import DesignParameters from './DesignParameters';
import ValueAdditions from './ValueAdditions';
import PanelDynamics from './PanelDynamics';
import Racking from './Racking';
import Roof from './Roof';
import { connect } from "react-redux"
import "./Add.scss"
import Error from "../Utilities/Error"
import {  logoutHandler , resetFields , resetStep } from "../../../actions/index"

// Material UI
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ColorlibConnector , ColorlibStepIcon } from "../Utilities/Stepper"

class Add extends Component {


    handleClick = () =>{

      this.props.logoutHandler();
      this.props.resetFields();
      this.props.resetStep();
    }

  render(){
    const { activeStep ,
      // userDetails 
    } = this.props;

    const steps = [
      {step_no : 0 , component : <Roof/>},
      {step_no : 1 , component : <PanelDynamics/>},
      {step_no : 2 , component : <InverterDynamics/>},
      {step_no : 3 , component : <Racking/>},
      {step_no : 4 , component : <DesignParameters/>},
      {step_no : 5 , component : <ValueAdditions/>}
    ]

    return (
      <div className="add">
        <div className="add__stepper">
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((step) => (
              <Step  key={step.step_no}>
                <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
              <div className="add__container">
                <div  className="add__line"></div>
                <div className="add__circleOne"></div>
                <div className="add__circleTwo"></div>
              </div>
        <div>
          {steps[activeStep].component}
        </div>
    
        <Error/>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    activeStep:state.add.activeStep,
    userDetails:state.auth.userDetails,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logoutHandler : () => dispatch(logoutHandler()),
    resetFields : () => dispatch(resetFields()),
    resetStep : () => dispatch(resetStep()),
  }
}

export default connect( mapStatesToProps, mapDispatchToProps )(Add);
