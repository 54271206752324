import React , { Component } from 'react';
import "./Packages.scss";
import "../Compare/Compare.scss";
import Mod from "./PackageForm.js";

class Packages extends Component {


  render(){


    return (
      <div>
      <div className="add__container">
        <div  className="add__line"></div>
        <div className="add__circleOne"></div>
        <div className="add__circleTwo"></div>
      </div>
      <div className="packages">
     <h1 className="packages__heading">Packages</h1>
     <div className="outer_div">
     <div className="div">
       <div className="inner_div">
         <div className="line" />
         <div className="heading">Bronze</div>
       </div>
       <div className="text">
         Review available proposals, Select the best offering, Advice areas for
         improvement (Design and Financials)
       </div>
        <div className="fee">Fee: 2% of selected proposition</div>
        <div style ={{width:"80%",margin:"20px auto",textAlign:"center"}}><Mod package="bronze"/></div>
     </div>
     <div className="vl"></div>
     <div className="div">
       <div className="inner_div">
         <div className="line" />
         <div className="heading">Silver</div>
       </div>
       <div className="text">
         Detailed engineering, Obtaining relevant approvals, Tender management,
         Contract execution.
       </div>
       <div className="fee">Fee: 7% of selected proposition</div>
       <div style ={{width:"80%",margin:"20px auto",textAlign:"center"}}><Mod package="silver"/></div>
     </div>
     <div class="vl"></div>
     <div className="div">
       <div className="inner_div">
         <div className="line" />
         <div className="heading">Gold</div>
       </div>
       <div className="text">
         Detailed engineering, Obtaining relevant approvals, Tender management,
         Contract management, Project supervision, Post install audit.
       </div>
        <div className="fee">Fee: 11% of selected proposition</div>
        <div style ={{width:"80%",margin:"20px auto",textAlign:"center"}}><Mod package="gold"/></div>
      </div>
     </div>
   </div>
   </div>
    )
  }
}

export default (Packages);
