import React, { Component } from 'react'
import { connect } from "react-redux"
import { nextStep  , onValueChange , setError , panelDetails } from "../../../actions/index"
import "./Roof.scss"
import { isEmpty , isPositive } from "../../../utils/validators"
import VendorName from "./VendorName"
class Roof extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleRoofMounting = (e) => {
    const { onValueChange } = this.props;

    this.handleChange(e);
    onValueChange({fieldName:"roofPitch",value:""})
  }

  handleClick = () =>{

    const {  customerCode , roofPitch , roofMounting , panelQty , roofAspect , nextStep , setError , onValueChange } = this.props;

    if(isEmpty({ customerCode , roofAspect , roofMounting , roofPitch , panelQty })){
      setError("Please Fill All The Fields")
    }
    else if(isPositive({ panelQty })){
      setError("Panel quantity should be greater than 0.")
    }
    else if(!/\d{4}$/.test(customerCode) || isPositive({customerCode}) || customerCode > 5000 ){
      setError("Invalid Customer Code [4 Digit Code , Between 0 to 5000]")
    }
    else{
      onValueChange({ fieldName:"errorText" , value:false })
      nextStep();
    }

  }

  render() {
    const { vendorName,customerCode , roofPitch , roofMounting , errorText , roofAspect , panelQty } = this.props;


    return (
      <div>
          <VendorName/>
      <div className="categories">

      <div className="roof">
        <h2 className="roof__heading">Roof Details - {vendorName}</h2>
        <div className="roof__form">
          <div className={ errorText && (customerCode === "" || !(/\d{4}$/.test(customerCode)) || customerCode < 0 || customerCode > 5000  ) ? "textfield error-border" :"textfield"}>
            <label htmlFor="customerCode">Post Code</label>
            <input type="number" placeholder="4 digit Post Code" name="customerCode" id="customerCode" value={customerCode} onChange={this.handleChange}/>
          </div>
          <div className={ errorText && roofMounting === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="roofMounting">Roof Mounting</label>
            <select name="roofMounting" onChange={this.handleRoofMounting} id="roofMounting" defaultValue={ roofMounting !=="" ? roofMounting: "default"}>
              <option value="default" hidden disabled > Select Option </option>
              <option value="Tilted">Tilted</option>
              <option value="Flush">Flush</option>
            </select>
          </div>
          <div className={ errorText && roofPitch === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="roofPitch">Roof Pitch</label>
            <select name="roofPitch" onChange={this.handleChange} id="roofPitch" value={ roofPitch !=="" ? roofPitch: "default"}  disabled={roofMounting===""}>
              <option value="default" hidden disabled > Select Option </option>
              <option value="Flat Pitch">Flat Pitch</option>
              <option hidden={roofMounting==="Tilted"} value="Standard Pitch">Standard Pitch</option>
              <option hidden={roofMounting==="Tilted"} value="Steep Pitch">Steep Pitch</option>
            </select>
          </div>
          <div className={ errorText && roofAspect === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="roofAspect">Roof Aspect</label>
            <select name="roofAspect" onChange={this.handleChange} id="roofAspect" defaultValue={ roofAspect !=="" ? roofAspect: "default"}>
              <option value="default" hidden disabled > Select Option </option>
              <option value="North">North</option>
              <option value="North-East">North-East</option>
              <option value="North-West">North-West</option>
              <option value="East">East</option>
              <option value="West">West</option>
            </select>
          </div>
          <div className={ errorText && (panelQty === "" || Number(panelQty) <= 0) ? "textfield error-border" :"textfield"}>
            <label htmlFor="panelQty">Panel Quantity</label>
            <input type="number" placeholder="Greater Than 0" name="panelQty" id="panelQty" value={panelQty} onChange={this.handleChange}/>
          </div>
        </div>
      </div>
    </div>
    <div className="button__form">
    <div>
    </div>
      <div>
    <button onClick={this.handleClick} className="roof__next ">
      <div className="nextbutton__form">
      <div className="next-button-text">Next</div>
      <div className="next-button-icon"><i className='fas fa-long-arrow-alt-right'></i></div>
      </div>
    </button>
       </div>
     </div>
    </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    customerCode : state.addComponent.customerCode,
    roofPitch : state.addComponent.roofPitch,
    roofMounting : state.addComponent.roofMounting,
    roofAspect : state.addComponent.roofAspect,
    panelQty : state.addComponent.panelQty,
    errorText : state.addComponent.errorText
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nextStep : () => dispatch(nextStep()),
    onValueChange : (e) => dispatch(onValueChange(e)),
    setError : (text) => dispatch(setError(text)),
    panelDetails : (panelManufacturer) => dispatch(panelDetails(panelManufacturer))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(Roof);
