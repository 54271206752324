import React, { Component } from 'react'
import { connect } from "react-redux"
import { nextStep , previousStep , onValueChange , setError } from "../../../actions/index"
import "./DesignParameters.scss"
import "./Roof.scss"
import { isEmpty } from '../../../utils/validators'


class DesignParameters extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleBack = () => {
    const { onValueChange , previousStep } = this.props;

    onValueChange({fieldName:"errorText",value:false})
    previousStep();
  }

  handleClick = () =>{

    const { otherConsiderOne , safetyConsider , roofUtilisation , otherConsiderTwo , elements , maintenance ,  nextStep , setError , onValueChange } = this.props;

    if( isEmpty({ otherConsiderOne , otherConsiderTwo , roofUtilisation , safetyConsider , elements , maintenance  })
    ){
      setError("Please Fill The All Fields");
    }
    else{
      onValueChange({fieldName:"errorText",value:false})
      nextStep();
    }
  }

  handleConsiderationOne = (e) => {
    const { onValueChange } = this.props;

    this.handleChange(e);
    onValueChange({fieldName:"otherConsiderTwo",value:""})
  }

  render() {

    const { vendorName,otherConsiderOne , otherConsiderTwo , safetyConsider , roofUtilisation , elements , maintenance ,  errorText } = this.props;

    return (
      <div>
      <div className="categories">
      <div className="design">
        <h2 className="design__heading" >DESIGN PARAMETERS - {vendorName}</h2>
        <div className="design__form">
          <div className={ errorText && safetyConsider === "" ? "textfield error-border" :"textfield" }>
            <label htmlFor="safetyConsider">Safety Considerations</label>
            <select name="safetyConsider" onChange={this.handleChange} id="safetyConsider" defaultValue={ safetyConsider ? safetyConsider: "default"} >
              <option value="default" hidden disabled > Select Option </option>
              <option value="Design">Design</option>
              <option value="Access to Roof">Access to Roof</option>
              <option value="None">None</option>
            </select>
          </div>
          <div className={ errorText && otherConsiderOne === "" ? "textfield error-border" :"textfield" }>
            <label htmlFor="otherConsiderOne">Design Considerations 1</label>
            <select
              name="otherConsiderOne"
              onChange={ this.handleConsiderationOne }
              id="otherConsiderOne"
              defaultValue={ otherConsiderOne ? otherConsiderOne: "default"}
            >
              <option value="default" hidden disabled > Select Option </option>
              <option value={0.7}>2.6M Exclusion Zone</option>
              <option value={0.75}>Guard Rail</option>
              <option value={0}>None</option>
            </select>
          </div>
          <div className={ errorText && otherConsiderTwo === "" ? "textfield error-border" :"textfield" }>
            <label htmlFor="otherConsiderTwo">Design Considerations 2</label>
            <select name="otherConsiderTwo" onChange={this.handleChange} id="otherConsiderTwo" value={ otherConsiderTwo !=="" ? otherConsiderTwo: "default"} disabled={otherConsiderOne===""}
            >
              <option value="default" hidden disabled > Select Option </option>
              <option hidden={otherConsiderOne==="0"} value={0.25}>Sky Light</option>
              <option hidden={otherConsiderOne==="0.7"} value={0}>None</option>
            </select>
          </div>

          <div className={ errorText && roofUtilisation === "" ? "radiobutton error-border" :"radiobutton"}>
            <label htmlFor="roofUtilisation">Roof Space Utilisation</label>
            <span className="textsize">
              <input type="radio" name="roofUtilisation" value="efficient" checked={roofUtilisation==="efficient"} onChange={this.handleChange}/> Efficient
            </span>
            <span className="textsize">
              <input type="radio" name="roofUtilisation" value="inefficient" checked={roofUtilisation==="inefficient"} onChange={this.handleChange}/> Inefficient
            </span>
          </div>
          <div className={ errorText && elements === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="elements">Influencing Elements</label>
            <select name="elements" onChange={this.handleChange} id="elements" defaultValue={ elements ? elements : "default" }>
              <option value="default" hidden disabled > Select Option </option>
              <option value="Slope">Slope</option>
              <option value="Purlins and Rafters">Purlins and Rafters</option>
              <option value="Shading">Shading</option>
            </select>
          </div>
          <div className={ errorText && maintenance === "" ? "textfield error-border" :"textfield"}>
            <label htmlFor="maintenance">Maintenance Access</label>
            <select name="maintenance" onChange={this.handleChange} id="maintenance" defaultValue={ maintenance !== "" ? maintenance : "default" } >
              <option value="default" hidden disabled> Select Option </option>
              <option value={1}>Inter Row Spacing</option>
              <option value={0.75}>Every 2 Row Spacing</option>
              <option value={0.5}>Every 3 Row Spacing</option>
              <option value={0.15}>Every 4 Row Spacing</option>
              <option value={0}>No</option>
            </select>
          </div>

        </div>
      </div>
        </div>
        <div className="button__form">
        <div>
        <button onClick={this.handleBack} className="roof__back">
        <div className="backbutton__form">
          <div className="back-button-icon"><i className='fas fa-long-arrow-alt-left'></i></div>
          <div className="back-button-text">Back</div>
        </div>
        </button>
           </div>
           <div>
         <button onClick={this.handleClick} className="roof__next ">
           <div className="nextbutton__form">
           <div className="next-button-text">Next</div>
           <div className="next-button-icon"><i className='fas fa-long-arrow-alt-right'></i></div>
           </div>
         </button>
            </div>
      </div>
    </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    safetyConsider : state.addComponent.safetyConsider,
    otherConsiderOne : state.addComponent.otherConsiderOne,
    otherConsiderTwo : state.addComponent.otherConsiderTwo,
    roofUtilisation : state.addComponent.roofUtilisation,
    elements : state.addComponent.elements,
    maintenance : state.addComponent.maintenance,

    errorText : state.addComponent.errorText,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nextStep : () => dispatch(nextStep()),
    previousStep : () => dispatch(previousStep()),
    onValueChange : (e) => dispatch(onValueChange(e)),
    setError : (text) => dispatch(setError(text))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(DesignParameters);
