import React , { Component } from 'react';
import { connect } from "react-redux"
import {  withRouter } from "react-router-dom";
import {  menuOneOpen , menuOneClose } from "../../actions/index"
import "./HomeScreen.scss";
import Menu from "../Pages/Menu/Menu";
import Modal from "./HomeScreenForm.js";

//Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import logo from "../../images/logo.png";
import logo2 from "../../images/logo2.png";
import tabimage from "../../images/tab__Image.jpg";
// import CloseIcon from '@material-ui/icons/Close';
// import { Slide } from '@material-ui/core';
// import { Directions } from '@material-ui/icons';

// Material UI Configurations

const styles = {

  root: {
    flexGrow: 1,
  },
  navbar:{
    background: "#FFF",
    height:"100px",
    boxShadow: "none"
  },
  photo:{
    border : "1px solid #000",
    borderRadius : "50%"
  },

  logo:{
    width: "220px",
    marginTop:"25px"  },

  image: {
    marginLeft: "3.3rem",
    flexGrow: 1,
    fontFamily:"'Manrope ' ,sans-serif",
    "@media(max-width:500px)":{
      fontSize: "1rem"
    }
  },

};

class HomeScreen extends Component {
  constructor() {
   super();
   this.handleSubmit = this.handleSubmit.bind(this);
 }

 handleSubmit(event) {

   const data = new FormData(event.target);

   fetch('https://eqgauge.com/api/query',
   {
     method: 'POST',
     body: data,
   });
 }


  handleMenu = (e) => {
    this.props.menuOneOpen(e.currentTarget);
  };

  handleClick = () =>{
    this.props.menuOneClose();
  }

  render(){
    const {classes } = this.props;

    return (
      <div>
      <div>
      <AppBar className={classes.navbar} position="fixed">
        <Toolbar>
          <div className={classes.image}>
            <img className={classes.logo} src={logo2} alt="Logo"/>
          </div>
              <Menu/>
        </Toolbar>
      </AppBar>
      </div>
      <div>
      <div className="outer__div">
      <div className="desc__div">
          <p className="heading__text">Automated Platform to Compare Product Offerings - ėq<span>guage</span></p>
          <p className="description">ėcologiq is creating and working on new ideas every day. One of our latest concepts brings clarity to our struggling customers facing trouble in deciding upon the best solar PV solution for them.</p>
          <br></br>
          <a href= "#form" className="description" style ={{textDecoration:"none"}}> Find <span>out more</span><img src="https://sunpay.solar/wp-content/uploads/2020/05/arrows-2.svg" alt="arrow" style={{width:"55px",marginLeft:"10px"}}/></a>
      </div>
      <div className="image___div">
          <img  src={tabimage} alt="tabImage"/>
      </div>
      </div>
  <Modal/>
    </div>
    </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    menuOne:state.nav.menuOne,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    menuOneOpen : (val) => dispatch(menuOneOpen(val)),
    menuOneClose : () => dispatch(menuOneClose())
  }
}

export default withRouter(withStyles(styles)(connect( mapStatesToProps , mapDispatchToProps )(HomeScreen)));
