import authReducer from "./authReducer"
import navReducer from "./navReducer"
import addReducer from "./addReducer"
import clientReducer from "./clientReducer"
import addComponentReducer from "./addComponentReducer"
import commonReducer from "./commonReducer"
import { combineReducers } from "redux"

const rootReducer = combineReducers({
  auth:authReducer,
  nav:navReducer,
  add:addReducer,
  common:commonReducer,
  addComponent:addComponentReducer,
  client:clientReducer
})

export default rootReducer;