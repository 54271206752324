import React, { Component } from 'react'
import { connect } from "react-redux"
import { onValueChange }from "../../actions/index"
import Navbar from "../Other/Utilities/Navbar"
// import Client from '../Other/Clients/Clients';
import Add from '../Other/AddCompany/Add';
import Compare from '../Other/Compare/Compare';
import { Route , Switch , NavLink } from "react-router-dom";
// import logo from "../../images/logo.png"
import "./Home.scss"
import Graph from '../Other/Graph/Graph';
import {  logoutHandler , resetFields , resetStep } from "../../actions/index"
import Packages from '../Other/Packages/Packages';
import ThankYou from '../Other/Packages/ThankYou';


class Home extends Component {

  handleClick = () =>{

    this.props.logoutHandler();
    this.props.resetFields();
    this.props.resetStep();
  }

  render() {
      const { 
        // activeStep ,
        userDetails } = this.props;
    let heading = ""
    if(this.props.location.pathname==="/home/add")
      heading = "Fill Details"
    if(this.props.location.pathname==="/home/compare")
      heading = "Comparison"
    if(this.props.location.pathname==="/home/graph")
      heading = "Graphs"
    if(this.props.location.pathname==="/home/packages")
      heading = "Packages"
    return (
      <div className="home">
        <Navbar/>
        <div className="home__nav">
          <div>
          <h1  style={{ width:"100%",textAlign: "center",fontSize: "2.2rem",margin:"30px 0 15px 0"}}  >
            {heading}
          </h1>
          </div>
          <div>
          {      (this.props.location.pathname!=="/home/packages" && this.props.location.pathname!=="/home/thankyou") ?
          <div className="home__nav__links">
            {/* <NavLink exact activeClassName="active" to="/home">Clients</NavLink> */}

            <NavLink onClick={()=>this.props.onValueChange({fieldName:"popupOpen",value:true})} exact activeClassName="active" to="/home">Add New Company </NavLink>
            <NavLink  exact activeClassName="active" to="/home/compare">Comparison Report</NavLink>
          </div>
          :
          <div></div>}
          </div>
        </div>
          <Switch>
            {/* <Route exact path="/home" component={Client} ></Route> */}
            <Route exact path="/home" component={Add} ></Route>
            <Route exact path="/home/compare" component={Compare} ></Route>
            <Route exact path="/home/graph" component={Graph} ></Route>
            <Route exact path="/home/packages" component={Packages} ></Route>
            <Route exact path="/home/thankyou" component={ThankYou}></Route>
          </Switch>
          <div className="add__containerTwo">
             <div className="add__lineTwo"></div>
               <div className="add__lineTwoContainer">
                 <div className="add__userDetails">{userDetails}</div>
                 <div className="add__verticalLine">|</div>
                 <div className="add__description">Business Executive</div>
               </div>
             <div  className="add__logout">
                  <button className="add__logoutButton" onClick={this.handleClick}>Logout</button></div>
            </div>
      </div>
    )
  }
}
const mapStatesToProps = (state) => {
  return {
    activeStep:state.add.activeStep,
    userDetails:state.auth.userDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onValueChange : (e) => dispatch(onValueChange(e)),
    logoutHandler : () => dispatch(logoutHandler()),
    resetFields : () => dispatch(resetFields()),
    resetStep : () => dispatch(resetStep())
  }
}

export default connect(  mapStatesToProps, mapDispatchToProps )(Home);
