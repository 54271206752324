export const PREV = "PREV";
export const NEXT = "NEXT";
export const RESET_STEPPER = "RESET_STEPPER";
export const CLEAR_FIELDS = "CLEAR_FIELD";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_DETAILS = "GET_DETAILS";
export const GET_SCORES = "GET_SCORES";
export const GET_GRAPH = "GET_GRAPH";

export const ONCHANGE = "ONCHANGE";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const HANDLE_CREDENTIALS = "HANDLE_CREDENTIALS";

export const MENU_ONE_OPEN = "MENU_ONE_OPEN";
export const MENU_ONE_CLOSE = "MENU_ONE_CLOSE";
export const MENU_TWO_OPEN = "MENU_TWO_OPEN";
export const MENU_TWO_CLOSE = "MENU_TWO_CLOSE";

export const MAIN_ERROR = "MAIN_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
