import React, { Component } from 'react';
import Routes from "./components/Other/Utilities/Routes"
import './App.scss';

//Redux
import { Provider } from "react-redux"
import store from './store';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faLock , faSolarPanel , faCogs , faPlus , faSlidersH } from '@fortawesome/free-solid-svg-icons'
library.add(faUser, faLock , faSolarPanel , faCogs , faPlus , faSlidersH ); 

class App extends Component {
  render() {
    return ( 
      <Provider store={store}>
        <Routes/>
      </Provider>
    );
  }
}

export default App;
