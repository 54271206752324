import { ONCHANGE , MAIN_ERROR , CLEAR_ERROR} from "./actionTypes"

export const onValueChange = (event) => ({ type : ONCHANGE , event : event})

export const setError = (text) => (dispatch) => {
  dispatch({ type : MAIN_ERROR , errorMsg : text})
  dispatch({ type : ONCHANGE , event : {fieldName:"errorText",value:true} })
}
  
export const clearError = () => ({ type : CLEAR_ERROR })

