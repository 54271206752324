import { ONCHANGE , ADD_COMPANY , CLEAR_FIELDS , GET_DETAILS , GET_SCORES , GET_GRAPH } from '../actions/actionTypes'

const intialState = {
  allCompanies:[],

  vendorName:"",
  popupOpen:true,
  errorPop:false,

  customerCode : "", 
  roofPitch : "", 
  roofMounting : "" , 
  roofAspect : "", 
  panelQty : "",

  panelManufacturer:"",
  panelCells:"",
  panelWatt:"",
  panelEfficiency:0,
  panelWarranty:"",
  selectedPanel:null,
  manfacturerDetails:null,
  
  inverterManufacturer:"",
  inverterWatt:"",
  mppt:"",
  selectedInverter:null,
  
  rackingDetails:"",
  inverterEase:"",
  localPresence:"",
  strConsider:"",
  selectedRacker:null,

  otherConsiderOne:"",
  otherConsiderTwo:"",
  safetyConsider:"",
  roofUtilisation:"",
  elements:"",
  maintenance:"",
  optimiser:"",
  inverterLocation:"",

  monitoring:"",
  finance:"",
  financeVal:"",
  rebate:"",
  performance:"",
  performanceVal:"",
  projValue:"",
  sysSize:"",
  yearFive:"",
  yearOne:"",
  yearTen:"",

  searching:false,
  scores:null,
  graphScores:null,
  graphYears:null,
  graphSelection:true,

  errorText:false
}

export default (state = intialState , action ) => {  

  switch(action.type){
    case ONCHANGE :
      if(action.event.target!==undefined){
        return {
         ...state,
         [action.event.target.name] : action.event.target.value
        }
      }
      else {
        return {
          ...state,
          [action.event.fieldName] : action.event.value
        }
      }
    case CLEAR_FIELDS :{
      const { allCompanies , ...initState} = intialState;
      return {
        ...state,
        ...initState
      }
    }
    case GET_DETAILS:
      return{
        ...state,
        manfacturerDetails:action.manfacturerDetails,
        searching:false  
      }
    case GET_SCORES:
      return{
        ...state,
        scores:action.scores ,
        searching:true  
      }
    case GET_GRAPH:
      return{
        ...state,
        graphScores:action.graphScores ,
        graphYears:action.graphScores_2 ,
        searching:true  
      }
    case ADD_COMPANY :  
      return {
        ...state,
        allCompanies : [ ...state.allCompanies , action.data]
      }
    default :
      return state;    
  }
}