import React, { Component } from 'react'
import { connect } from "react-redux"
import { clearError } from "../../../actions/index"

//Material UI
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Error extends Component {
  render() {
    const { error , errorMsg , clearError } = this.props;

    return (
      <Snackbar onClose={clearError} open={error} autoHideDuration={3000} >
        <Alert severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    )
  }
}

const mapStatesToProps = (state) => { 
  return {
    error : state.common.error, 
    errorMsg : state.common.errorMsg
  }
} 

const mapDispatchToProps = (dispatch) => { 
  return {
    clearError : () =>dispatch(clearError())
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(Error); 