import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../Menu/Menu.css";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';


const Burger = styled.div`
    padding: 0.75rem;
    position: fixed;
    top: 40px;
    right: 40px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    cursor:pointer;

  div {
    background-color:#000;
    width: 25px;
    margin-bottom:0.25rem;
    height: 2px;
    border: 2px solid #000;
    transform-origin: 1px;
    transition: all 0.6s linear;
  }
`;

const Ul = styled.ul`
  {
    list-style: none;
    flex-flow: column nowrap;
    position: fixed;
    background-color: #ffffff;
    top: 0;
    right: 0;
    height: 100vh;
    width: 40%;

    transition: transform 0.6s ease-in-out;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    &:hover{
        box-shadow:0px 2px 18px 0px rgba(0,0,0,0.3) !important;
    }
    li {
        padding:10px;
        cursor:pointer;
    }
  }
`;

const Menu = () => {

    const[open,setOpen]=useState(false);

    return (
        <div>
            <Burger open={open} onClick={()=>setOpen(!open)}>
                        <div/>
                        <div/>
                        <div/>
            </Burger>
            <Ul open={open} >
              <CloseIcon open={open} style={{position: "realtive",float:"right",fontSize: '34px',marginTop:"15%",marginRight:"8%",color:"#000000",cursor:"pointer"}} onClick={()=>setOpen(!open)}/>
              <li style={{marginBottom:"10px"}}><Link to="/login" className="portal">portal login</Link> </li>
              <li><a className="tendering" href = "mailto:info@ecologiq.com.au">tendering</a></li>
            </Ul>
        </div>
    )
}

export default Menu
