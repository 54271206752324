import React from "react";
import Modal from "@material-ui/core/Modal";
import "./HomeScreen.scss";
// import { Link } from "react-router-dom";


export default function SimpleModal() {


  // getModalStyle is not a pure function, we roll the style only on the first render
  const initialFormData = Object.freeze({
  name:"",
  email:"",
  phone_no:"",
  company:"",
  package:""
});
const [formData, updateFormData] = React.useState(initialFormData);

const handleChange = (e) => {
  updateFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

  const [open, setOpen] = React.useState(false);

  const handleOpen = (e) => {
  e.preventDefault();
      fetch('https://eqgauge.com/api/query',
      {
        method: 'POST',
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(formData),
      });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (

<div className="modal">
<div className="modal__text">
<h1>Thankyou for showing interest we will get back to you.</h1>
<button onClick={handleClose} className="input__button" style={{textAlign:"center"}}>Close</button>
</div>
</div>
  );

  return (
    <div>
    <div id= "form" className="form">
  <h1 className="form__heading"><span style ={{color:"#1E957C"}}>Fill out the form</span> below to contact the ėcologiq team.</h1>
  <form >
          <input className="input__fieldOne" onChange={handleChange} id="name" name="name" type="text" placeholder="Name" />
          <input className="input__fieldTwo" onChange={handleChange} id="company" name="company" type="text"  placeholder="Company"/><br></br>
          <input className="input__fieldOne" onChange={handleChange} id="email" name="email" placeholder="E-mail" />
          <input className="input__fieldTwo" onChange={handleChange} id="phone_no" name="phone_no" type="tel"  placeholder="Phone" /><br></br>
          <div style={{width:"100%",textAlign:"right",marginRight:"20px"}}>
          <input type="submit" value="Submit" onClick={handleOpen} className="input__button"/>
          <img src="https://sunpay.solar/wp-content/uploads/2020/05/arrows-2.svg" alt="arrow" style={{width:"55px"}}/>
          </div>
        </form>
        <p style={{margin:"8vw 0 20px 0",fontSize:"17px"}}>2020 © ecologiq. All rights reserved.</p>
    </div>
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
    </div>
  );
}
