import { MENU_ONE_CLOSE , MENU_ONE_OPEN , MENU_TWO_CLOSE , MENU_TWO_OPEN } from "../actions/actionTypes"

const intialState = {
  menuOne:null,
  menuTwo:null
}

export default (state = intialState , action ) => {
  
  switch(action.type){
    case MENU_ONE_OPEN :
      return {
        ...state,
        menuOne : action.val
      }
    case MENU_ONE_CLOSE :
      return {
        ...state,
        menuOne : null
      }
    case MENU_TWO_OPEN :
      return {
        ...state,
        menuTwo : action.val
      }
    case MENU_TWO_CLOSE :
      return {
        ...state,
        menuTwo : null
      }
    default :
    return { ...state }    
  }
}
