import React, { Component } from 'react'
import { connect } from "react-redux"
import { onValueChange } from "../../../actions/index"
import { isEmpty } from "../../../utils/validators"
import "./VendorName.scss"
// Material UI
import Modal from '@material-ui/core/Modal';
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class VendorName extends Component {



  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleClick = () =>{

    const { vendorName , onValueChange } = this.props;

    if(isEmpty({ vendorName }))
      onValueChange({ fieldName:"errorPop" , value:true })
    else
      onValueChange({ fieldName:"popupOpen" , value:false })

  }

  render() {
    const { popupOpen , errorPop , vendorName , onValueChange } = this.props;

    return (
      <Modal
        open={popupOpen}
        disableBackdropClick
        onClose={() => onValueChange({ fieldName:"popupOpen" , value:false })}
      >
        <div className="namepopup">
          <div className="textfield">
            <label htmlFor="vendorName">Vendor Name</label>
            <input type="text" placeholder="Enter Name" name="vendorName" id="vendorName"  value={vendorName} onChange={this.handleChange}/>
          </div>
          <button onClick={this.handleClick} className="namepopup__sub">Submit</button>
        <Snackbar
          onClose={()=>onValueChange({ fieldName:"errorPop" , value:false })}
          open={errorPop}
          autoHideDuration={3000} >
          <Alert severity="error">
            Enter a name.
          </Alert>
        </Snackbar>
        </div>
      </Modal>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    popupOpen : state.addComponent.popupOpen,
    errorPop : state.addComponent.errorPop
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onValueChange : (e) => dispatch(onValueChange(e))
  }
}

export default connect( mapStatesToProps , mapDispatchToProps )(VendorName);
