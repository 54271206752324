import React , { Component } from 'react';
import { connect } from "react-redux"
import { Link , withRouter } from "react-router-dom";
import {  menuTwoOpen , menuTwoClose , logoutHandler , resetFields , resetStep , onValueChange } from "../../../actions/index"

//Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import logo from "../../../images/logo.png";


// Material UI Configurations

const styles = {

  root: {
    flexGrow: 1,
  },
  navbar:{
    background: "#FFF",
    height:"100px"
  },
  photo:{
    border : "1px solid #000",
    borderRadius : "50%"
  },
  menuButton: {
    marginRight: "1rem"
  },
  navTopRow:{
    width:"100%",
    height:"23px",
    position:"relative"

  },
  line:{
    border: "13px solid black",
    borderRadius: "10px 10px 0px 0px",
    position:"absolute",
    backgroundColor:"black",
    top:"0",left:"0",width:"100%",height:"100%"
  },
  settings:{
    marginTop:"20px"
  },
  circleOne:{
    backgroundColor: '#1E957C',
    zIndex: "1",
    position:"absolute",
    width: "10px",
    height: "10px",
    top:"8px",
    left:"13px",
    borderRadius: '50%'
  },
  circleTwo:{
    backgroundColor: '#FFF',
    zIndex: "1",
    position:"absolute",
    width: "10px",
    height: "10px",
    top:"8px",
    left:"28px",
    borderRadius: '50%'
  },
  logo:{
    width: "190px",
    marginTop:"20px"
  },
  navBottomRow:{
    border: "1px solid black",
    position:"absolute",
    backgroundColor:"black",
    top:"122px",
    width:"100%"
  },
  menucolor:{
      color:"#000"
  },
  user: {
    marginLeft: "0.7rem",
    flexGrow: 1,
    fontFamily:"'Manrope ' ,sans-serif",
    "@media(max-width:500px)":{
      fontSize: "1rem"
    }
  },
  title: {
    right: "55px",
    position: "absolute",
    color:"#000",
    flexGrow: 1,
    marginTop:"20px",
    textTransform: "uppercase",
    fontFamily:"'Manrope ' ,sans-serif",
    "@media(max-width:500px)":{
      display: "none"
    }
  },
  subText:{
    fontSize: "0.8rem",
    marginTop: "0.25rem",
    fontFamily:"'Manrope ' ,sans-serif",
    "@media(max-width:500px)":{
      fontSize: "0.5rem"
    }
  },
  menuItem:{
    fontWeight: "100",
    fontFamily:"'Manrope ' ,sans-serif",

    "&:hover":{
      background:"#EAF2F0",
      transition : "all 0.3s"
    }
  },
  link:{
    textDecoration:"none",
    width:"100%",
    "&:visited":{
      color:"#000"
    }
  }
};

class Navbar extends Component {

  handleMenu = (e) => {
    this.props.menuOneOpen(e.currentTarget);
  };

  handleMenu_2 = (e) => {
    this.props.menuTwoOpen(e.currentTarget);
    this.props.menuTwoOpen(e.currentTarget);
  };

  handleClick = () =>{
    this.props.menuTwoClose()
    this.props.logoutHandler();
    this.props.resetFields();
    this.props.resetStep();
  }

  render(){
    const {classes , menuTwo , menuTwoClose , userDetails , onValueChange } = this.props;

    return (
      <div>
      <div className={classes.navTopRow}>
        <div className={classes.line}></div>
        <div className={classes.circleOne}></div>
        <div className={classes.circleTwo}></div>
      </div>

      <AppBar className={classes.navbar} position="static">
        <Toolbar>
          <div className={classes.user}>
            <img className={classes.logo} src={logo} alt="Logo"/>
          </div>
          <Typography  variant="h6" className={classes.title}>
            MENU
          </Typography>
          <div className="Menu-2">
            <IconButton
              edge="end"
              className={classes.settings}
              color="inherit"
              aria-label="menu-appbar"
              onClick={this.handleMenu_2}
              >
            <MenuIcon className={classes.menucolor}/>
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={menuTwo}
              open={Boolean(menuTwo)}
              onClose={menuTwoClose}
            >
            <MenuItem>
              <AccountCircle className={classes.photo}  fontSize="large" />
              <div className={classes.user}>
                <h1 style = {{fontSize:"1rem",fontWeight:"100"}}>{userDetails}</h1>
                <p className={classes.subText}>Business Executive</p>
              </div>
            </MenuItem>

            <MenuItem className={classes.menuItem} >
               <Link
                onClick={()=>{
                 menuTwoClose()
                 onValueChange({fieldName:"popupOpen",value:true});
                }}
                className={classes.link}
                to="/home">
                  Add New Company
                </Link>
              </MenuItem>
              <MenuItem className={classes.menuItem} >
                <Link onClick={menuTwoClose} className={classes.link} to="/home/compare">Comparison Report</Link>
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={this.handleClick}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <hr className={classes.navBottomRow}  />
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    menuTwo:state.nav.menuTwo,
    userDetails:state.auth.userDetails
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    menuTwoOpen : (val) => dispatch(menuTwoOpen(val)),
    menuTwoClose : () => dispatch(menuTwoClose()),
    logoutHandler : () => dispatch(logoutHandler()),
    resetFields : () => dispatch(resetFields()),
    resetStep : () => dispatch(resetStep()),
    onValueChange : (e) => dispatch(onValueChange(e))
  }
}

export default withRouter(withStyles(styles)(connect( mapStatesToProps , mapDispatchToProps )(Navbar)));
