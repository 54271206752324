import React, { Component } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { previousStep , addCompany , onValueChange , setError } from "../../../actions/index";
import "./ValueAdditions.scss"
import "./Roof.scss"
import "./DesignParameters.scss"
import { isEmpty , isPositive } from '../../../utils/validators';

class ValueAdditions extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e)
  }

  handleBack = () => {
    const { onValueChange , previousStep } = this.props;

    onValueChange({fieldName:"errorText",value:false})
    previousStep();
  }

  handleClick = (e) =>{

    const {
      monitoring , finance , rebate , sysSize , projValue , performance,
       setError , addCompany  , financeVal , history , onValueChange , yearFive , yearOne , yearTen } = this.props;


    const inputs = ({ monitoring , rebate , projValue , sysSize , performance , finance });
    if( finance === "yes" )
    inputs[financeVal]=financeVal;

    if( isEmpty(inputs) )
      setError("Please Fill The All Fields");
    else if( isPositive({sysSize,projValue,yearFive,yearOne,yearTen}) )
      setError("Invalid System Size or Project Value or Years");
    else{
      onValueChange({fieldName:"errorText",value:false})
      addCompany();
      if(e.target.value==="done")
        history.push("/home/compare");
    }
  }

  render() {
    const { vendorName,monitoring , finance , rebate , sysSize , projValue , performance , errorText , financeVal , onValueChange , yearTen , yearOne , yearFive } = this.props;

    return (
   <div>
      <div className="categories">
      <div className="value">
        <h2 className="value__heading">VALUE ADDITIONS - {vendorName}</h2>
        <div className="value__form">
          <div className={ errorText && monitoring === "" ? "radiobutton error-border" :"radiobutton" }>
            <label htmlFor="monitoring">Additional Monitoring</label>
            <span className="textsize">
              <input type="radio" name="monitoring" value="free" checked={monitoring==="free"} onChange={this.handleChange}/> Free
            </span>
            <span className="textsize">
              <input type="radio" name="monitoring" value="thirdparty" checked={monitoring==="thirdparty"} onChange={this.handleChange}/> Third Party
            </span>
          </div>
          <div className={ errorText && finance === "" ? "iconbutton error-border" :"iconbutton" }>
            <label htmlFor="finance">Finance Option</label>
            <input className="iconbutton--yes" type="radio" name="finance" value="yes" checked={finance==="yes"} onChange={this.handleChange}/> Yes
            <input
              className="iconbutton--no"
              type="radio"
              name="finance"
              value="no"
              checked={finance==="no"}
              onChange={(e)=>{
                this.handleChange(e);
                onValueChange({fieldName:"financeVal",value:""})
              }}
            /> No
          </div>
          <div className={ errorText && finance === "yes" && financeVal==="" ? "textfield error-border" :"textfield" }>
            <label htmlFor="elements">Type Of Finance</label>
            <select disabled={finance!=="yes" } name="financeVal" onChange={this.handleChange} id="financeVal" value={ financeVal !== "" ? financeVal : "default"}>
              <option value="default" hidden disabled > Select Option </option>
              <option value="Power Purchase Agreement">Power Purchase Agreement</option>
              <option value="EUA">EUA</option>
              <option value="Rental">Rental</option>
            </select>
          </div>
          <div className={ errorText && rebate === "" ? "iconbutton error-border" :"iconbutton" }>
            <label htmlFor="rebate">Council Rebate</label>
            <input className="iconbutton--yes" type="radio" name="rebate" value="yes" checked={rebate==="yes"} onChange={this.handleChange}/> Yes
            <input className="iconbutton--no" type="radio" name="rebate" value="no" checked={rebate==="no"} onChange={this.handleChange}/> No
          </div>
          <div className={ errorText && performance === "" ? "textfield error-border" :"textfield" }>
            <label htmlFor="performance">Performance Guarantee</label>
            <select name="performance" onChange={this.handleChange} id="performance" defaultValue={ performance !== "" ? performance : "default"}>
              <option value="default" hidden disabled > Select Option </option>
              <option value={0.3}>3 Years</option>
              <option value={0.7}>5 Years</option>
              <option value={1}>10 Years</option>
            </select>
          </div>
          <div className={ errorText && (projValue === "" || Number(projValue) <= 0) ? "textfield error-border" :"textfield" }>
            <label htmlFor="projValue">Project Value (Ex GST)</label>
            <input type="number" placeholder="in $ (Greater Than 0)" name="projValue" id="projValue" value={ projValue } onChange={this.handleChange}/>
          </div>
          <div className={ errorText && (sysSize === "" || Number(sysSize) <= 0) ? "textfield error-border" :"textfield" }>
            <label htmlFor="sysSize">System Size</label>
            <input type="number" placeholder="in kW (Greater Than 0)" name="sysSize" id="sysSize" value={ sysSize } onChange={this.handleChange}/>
          </div>

          <div className="value__form__last">
            <div className={ errorText && (yearOne === "" || Number(yearOne) <= 0) ? "textfield error-border width-one-third" :"textfield width-one-third" }>
              <label htmlFor="yearOne">Year 1 (Savings)</label>
              <input type="number" placeholder="in $ (Greater Than 0)" name="yearOne" id="yearOne" value={ yearOne } onChange={this.handleChange}/>
            </div>
            <div className={ errorText && (yearFive === "" || Number(yearFive) <= 0) ? "textfield error-border width-one-third" :"textfield width-one-third" }>
              <label htmlFor="yearFive">Year 5 (Savings)</label>
              <input type="number" placeholder="in $ (Greater Than 0)" name="yearFive" id="yearFive" value={ yearFive } onChange={this.handleChange}/>
            </div>
            <div className={ errorText && (yearTen === "" || Number(yearTen) <= 0) ? "textfield error-border width-one-third" :"textfield width-one-third" }>
              <label htmlFor="yearTen">Year 10(Savings)</label>
              <input type="number" placeholder="in $ (Greater Than 0)" name="yearTen" id="yearTen" value={ yearTen } onChange={this.handleChange}/>
            </div>
    </div>
        </div>

      </div>
      </div>

      <div className="valuebutton__form">
      <div>
      <button onClick={this.handleBack} className="roof__back width">
      <div className="backbutton__form">
        <div className="back-button-icon"><i className='fas fa-long-arrow-alt-left'></i></div>
        <div className="back-button-text">Back</div>
      </div>
      </button>
  </div>
       <div>
       <button value="add" onClick={this.handleClick} className="value__more">
         Add Company
       </button>
     </div>
     <div>

   <button value="done" onClick={this.handleClick} className="roof__next width">
       Done
     </button>

      </div>
  </div>
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    vendorName : state.addComponent.vendorName,
    monitoring : state.addComponent.monitoring,
    performance : state.addComponent.performance,
    sysSize : state.addComponent.sysSize,
    projValue : state.addComponent.projValue,
    finance : state.addComponent.finance,
    rebate : state.addComponent.rebate,
    financeVal : state.addComponent.financeVal,
    errorText : state.addComponent.errorText,
    yearOne : state.addComponent.yearOne,
    yearFive : state.addComponent.yearFive,
    yearTen : state.addComponent.yearTen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    previousStep : () => dispatch(previousStep()),
    onValueChange : (e) => dispatch(onValueChange(e)),
    setError : (text) => dispatch(setError(text)),
    addCompany : () => dispatch(addCompany())
  }
}

export default withRouter(connect( mapStatesToProps , mapDispatchToProps )(ValueAdditions));
