import { PREV , NEXT , RESET_STEPPER  } from "../actions/actionTypes"

const intialState = {
  activeStep:0
}

export default (state = intialState , action ) => {  
  
  switch(action.type){
    case NEXT :
      return {
        ...state,
        activeStep : state.activeStep + 1
      }
    case PREV :
      return {
        ...state,
        activeStep : state.activeStep - 1
      }
    case RESET_STEPPER :
      return {
        ...state,
        activeStep : 0
      }
    default :
      return state;    
  }
}
