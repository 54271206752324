import React , { Component } from 'react';
import { connect } from "react-redux"
import { getScores , onValueChange } from "../../../actions/index"
import "./Compare.scss";
import "../AddCompany/Roof.scss";
//Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#000",
    color: "#fff",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: "#EAF2F0",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#fff",
    }
  },
}))(TableRow);

const styles = {

  table:{
    maxWidth:"800px",
    border : "3px solid #000",
    margin : "3rem auto"
  },

  cells:{
    fontSize:"1.35rem",
    fontFamily:"'Manrope' ,sans-serif",
    fontWeight:"bold",
    "@media(max-width:500px)":{
      fontSize:"0.75rem"
    }
  }
}

class Compare extends Component {

  componentDidMount(){
    const { allCompanies , getScores } = this.props;
    getScores(allCompanies);
  }

  handleClick = () => {
    this.props.onValueChange({ fieldName:"searching" , value:false })
    this.props.history.push("/home/graph")
  }

  tableContent = () => {

    const { allCompanies , scores } = this.props

    if(allCompanies.length === 0)
      return null;
    else {



      let content = [
        { fieldName : "Project Value" , values: [] },
        { fieldName : "System Size" , values: [] },
        { fieldName : "Solar Panel" , values: [] },
        { fieldName : "Panel Wattage" , values: [] },
        { fieldName : "Panel Warranty" , values: [] },
        { fieldName : "Inverter" , values: [] },
        { fieldName : "Structural Considerations" , values: [] },
        { fieldName : "Safety Considerations" , values: [] },
        { fieldName : "Additional Monitoring" , values: [] },
        { fieldName : "Finance Options" , values: [] },
        { fieldName : "Score" , values: [] }
      ]



      allCompanies.forEach( vendor => {
        content[0].values.push("$ "+vendor.project_value);
        content[1].values.push(vendor.system_size + " kW");
        content[2].values.push(vendor.panel_manu);
        content[3].values.push(vendor.panel_wattage + " W");
        content[4].values.push(vendor.panel_warranty_string);
        content[5].values.push(vendor.inverter_manu);
        content[6].values.push(vendor.structural_eng === 0.25 ? "✓" : "✗" );
        content[7].values.push(vendor.safety_considerations === 0 ? "✗" : "✓" );
        content[8].values.push(vendor.monitoring === 1 ? "✓" : "✗" );
        content[9].values.push(vendor.finance_option === 1 ? "✓" : "✗" );
      })

      scores.forEach( score => {
        content[10].values.push((score*100).toFixed(2)+"%");
      })
      return content;
    }
  }

  render(){

    const { classes , allCompanies , searching } = this.props;
    const displayItems = searching ? this.tableContent() : null;

    return (
      <div>
      <div className="add__container">
        <div  className="add__line"></div>
        <div className="add__circleOne"></div>
        <div className="add__circleTwo"></div>
      </div>
      <div className="compare">
        <h1 className="compare__heading">Comparison</h1>
        {
          !searching ?
          <h2 className="compare__heading">Loading...</h2> :
          (displayItems  ?
          <React.Fragment>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.cells} align="center" >Details</StyledTableCell>
                  { allCompanies.map( (vendor,index) => {
                    return(<StyledTableCell className={classes.cells} key={index} align="center">{vendor.vendorName}</StyledTableCell>)
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                { displayItems.map((company) => (
                  <StyledTableRow key={company.fieldName}>
                    <StyledTableCell className={classes.cells} align="center">{company.fieldName}</StyledTableCell>
                    { company.values.map( (val , index) =>{
                      return(
                        <StyledTableCell className={classes.cells} key={index} align="center">{val}</StyledTableCell>
                      )
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>

          </React.Fragment>

          :
          <h4 className="compare__heading" >No Vendors To Compare</h4>
          )
        }
      </div>
      <div className="button__form">
      <div>
      </div>
        <div>
      <button onClick={this.handleClick} className="roof__next ">
        <div className="nextbutton__form">
        <div className="next-button-text">Next</div>
        <div className="next-button-icon"><i className='fas fa-long-arrow-alt-right'></i></div>
        </div>
      </button>
         </div>
       </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    allCompanies:state.addComponent.allCompanies,
    searching:state.addComponent.searching,
    scores:state.addComponent.scores,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getScores : (allVendors) => dispatch(getScores(allVendors)),
    onValueChange : (e) => dispatch(onValueChange(e))
  }
}

export default withStyles(styles)(connect( mapStatesToProps , mapDispatchToProps )(Compare));
