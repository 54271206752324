import { PREV , NEXT , CLEAR_FIELDS , RESET_STEPPER , ADD_COMPANY , GET_DETAILS , GET_SCORES  , GET_GRAPH } from "./actionTypes"

export const previousStep = () => ({ type : PREV })

export const nextStep = () => ({ type : NEXT })

export const resetStep = () => ({ type : RESET_STEPPER })

export const resetFields = () => ({ type : CLEAR_FIELDS })

export const addCompany = () => ( dispatch , getState ) => {

    const { panelManufacturer , panelWatt , panelEfficiency , panelWarranty , inverterManufacturer , inverterWatt , mppt , rackingDetails , inverterEase ,localPresence , strConsider , otherConsiderOne , otherConsiderTwo , maintenance , optimiser , monitoring , finance ,rebate , performance ,projValue , sysSize , customerCode , roofPitch , roofMounting , roofAspect , panelQty , yearOne , yearFive , yearTen , vendorName } = getState().addComponent;
    var panelWarrantyString = "";
    if(Number(panelWarranty)===0)
    panelWarrantyString="Less than 5 years";
    else
    if(Number(panelWarranty)===0.08)
    panelWarrantyString="Between 5-10 years";
    else
    if(Number(panelWarranty)===0.1)
    panelWarrantyString="Between 10-12 years";
    else
    if(Number(panelWarranty)===0.25)
    panelWarrantyString="More than 12 years";


    const newCompany =  {
    vendorName : vendorName ,
    panel_manu : panelManufacturer,
    panel_wattage : Number(panelWatt),
    panel_efficiency : panelEfficiency,
    panel_warranty : Number (panelWarranty),
    panel_warranty_string :  panelWarrantyString,
    inverter_manu : inverterManufacturer,
    inverter_wattage : Number(inverterWatt),
    multiple_mppt : mppt === "yes" ? 0.25 : 0 ,

    racking : Number(rackingDetails),
    local_presence : localPresence === "yes" ? 0.15 : 0 ,
    ease_of_installation : inverterEase === "yes" ? 0.1 : 0 ,
    structural_eng : strConsider === "yes" ? 0.25 : 0 ,

    maintenance_access : Number(maintenance),
    optimizer : Number(optimiser),
    safety_considerations : 0,
    other_considerations_1 : Number(otherConsiderOne),
    other_considerations_2 : Number(otherConsiderTwo),

    monitoring : monitoring === "thirdparty" ? 1 : 0 ,
    council_rebate : rebate === "yes" ? 1 : 0 ,
    finance_option : finance === "yes" ? 1 : 0 ,
    system_size : Number(sysSize),
    project_value : Number(projValue),
    performance_gurantee : Number(performance),
    year1 : Number(yearOne),
    year2 : Number(yearFive),
    year3 : Number(yearTen),

    customer_code : Number(customerCode),
    property_roof_pitch : roofPitch,
    property_roof_mounting : roofMounting,
    roof_aspect : roofAspect,
    panel_qty : Number(panelQty)
  }

  dispatch({ type : ADD_COMPANY , data : newCompany })
  dispatch({ type : CLEAR_FIELDS })
  dispatch({ type : RESET_STEPPER })
}

export const getScores = (allVendor) => async (dispatch) => {

  const scoreData = allVendor.map( vendor => {
    const { property_roof_mounting , panel_qty , customer_code , roof_aspect , property_roof_pitch , ...filteredVendor } = vendor;
    return filteredVendor;
  })

  const res = await fetch(`${process.env.REACT_APP_API_URL}/computes`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
    },
    body:JSON.stringify(scoreData)
  })

  const resData = await res.json()
  if(res.status===200){
    const scores = Object.keys(resData.data).map((key) => {
      return resData.data[key]
    });
    dispatch({ type : GET_SCORES , scores : scores })
  }
}

export const getGraphs = (allVendor) => async (dispatch) => {

  const graphData = allVendor.map( vendor => {

  const { property_roof_mounting , panel_qty , customer_code , roof_aspect , property_roof_pitch , system_size } = vendor;

  return { property_roof_mounting , panel_qty , customer_code , roof_aspect , property_roof_pitch , system_size };

  })

  const res = await fetch(`${process.env.REACT_APP_API_URL}/compare/generation`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json",
    },
    body:JSON.stringify(graphData)
  })

  const resData = await res.json()

  const graphData_2 = allVendor.map( vendor => {

    const { year1 , year2 , year3 } = vendor;
    return { year1 , year2 , year3 };

    })

    const res_2 = await fetch(`${process.env.REACT_APP_API_URL}/savings`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
      },
      body:JSON.stringify(graphData_2)
    })

    const resData_2 = await res_2.json()

  if(res.status===200 && res_2.status===200)
    dispatch({ type:GET_GRAPH , graphScores:resData , graphScores_2:resData_2})

}

export const panelDetails = (panelManufacturer) => async (dispatch) => {

  const res = await fetch(`${process.env.REACT_APP_API_URL}/paneldetails/${panelManufacturer}`, {
    method:"GET"
  })

  const resData = await res.json()

  if(res.status === 202){
    dispatch({
      type : GET_DETAILS ,
      manfacturerDetails : resData.map(item =>{
        return({
          wattage:item.wattage || 0,
          efficiency:item.efficiency || 0
        })
      })
    })
  }
}
